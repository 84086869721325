const Info = () => {
  return (
    <div className="flex gap-6 justify-between p-4">
      <div className="flex-1 bg-slate-300 p-4">
        <p className="font-semibold">
          다양한 온라인 서비스들을 구축하고 운영해온 풍부한 경험으로 UI/UX
          기획과 디자인 그리고 구축에서 운영까지, Webchemist는 고객의 디지털
          서비스를 위해 수준 높은 결과물을 제작하는 디지털 에이전시 입니다.
        </p>
      </div>
      <div className=" flex-1 bg-zinc-600 p-4">aaa</div>
    </div>
  );
};

export default Info;
