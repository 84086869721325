const Footer = () => {
  return (
    <div className="w-full bg-gray-800 text-gray-400 p-12">
      <p>웹케미스트 주식회사</p>
      <p>
        대표이사 : 윤원열 | 사업자등록번호 : 186-57-00647 | 통신판매업 : 111111
      </p>
      <p className="pb-6">고객센터 : cs@webchemist.net</p>
      <p>copyright @ webchemist.</p>
      <p>All rights reserved</p>
    </div>
  );
};

export default Footer;
