const Banner = () => {
  return (
    <div className="relative pt-[200px] pb-4 w-full bg-[#F3F8FE] text-center">
      <p className="text-[20px]">당신이 원했던</p>
      <p className="text-[20px] pb-4">서비스의 모든 것</p>
      <p className="text-3xl font-bold pb-4">
        저희는 수준 높은 결과물을 만듭니다.
      </p>
      <p className="text-[20px] pb-4">
        여러분도 서비스를 운영하는 대표가 되실 수 있습니다.
      </p>
      <p className="text-[20px] pb-6">웹케미스트를 소개합니다.</p>
      <p className="text-4xl animate-bounce">👇🏻</p>
    </div>
  );
};

export default Banner;
