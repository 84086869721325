const Contract = () => {
  return (
    <div className="w-full p-4">
      <div className="relative bg-indigo-700 text-white rounded-lg h-[400px] px-10 py-16">
        <h1 className="text-3xl font-bold pb-16">
          고객의 성공은 우리의 성공 Win! Win!
        </h1>
        <div className="md:flex md:justify-start">
          <div className="w-[150px]">
            <p className="text-[20px]">서비스</p>
            <p className="text-4xl font-bold">12개</p>
          </div>
          <div className="w-[150px]">
            <p className="text-[20px]">예비대표자</p>
            <p className="text-4xl font-bold">150명</p>
          </div>
          <div className="w-[150px]">
            <p className="text-[20px]">서비스 평균 매출</p>
            <p className="text-4xl font-bold">120억</p>
          </div>
        </div>
        <p className="absolute bottom-8 left-10 text-[20px]">신청하기 &gt;</p>
        <img
          className="absolute w-8/12 right-10 bottom-0"
          src="./assets/images/graph.png"
          alt="graph"
        />
      </div>
    </div>
  );
};

export default Contract;
