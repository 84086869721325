const Header = () => {
  return (
    <header className="fixed w-full py-4 px-6 z-50">
      <h1 className="relative text-3xl font-bold z-10">Webchemist</h1>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-white z-0 opacity-90" />
    </header>
  );
};

export default Header;
